var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"widget-content"},[_c('article',{staticClass:"widget-headline"},[_c('Editor',{staticClass:"textinput px-3 pt-3 pb-0",attrs:{"api-key":_vm.tinyAPI,"init":{
				plugins: 'quickbars',
				quickbars_selection_toolbar: 'bold italic',
				quickbars_insert_toolbar: '',
				menubar: false,
				statusbar: false,
				inline: true,
				toolbar: false,
				forced_root_block: 'h1'
			}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-row',[_c('v-col',{staticClass:"mt-10",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.headlines,"item-text":'txt',"item-value":'value',"label":"Title style","dense":"","outlined":""},on:{"change":function($event){return _vm.setStyle(_vm.style)}},model:{value:(_vm.style),callback:function ($$v) {_vm.style=$$v},expression:"style"}})],1)],1)],1),_c('article',[(_vm.message)?_c('form-message',{attrs:{"type":_vm.widgetMessageStatus}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1),_c('div',{staticClass:"widget-bullet-list__button"},[_c('v-btn',{staticClass:"w-100 admin-primary-button primary-contrast-background",attrs:{"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v("Done")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }