var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"widget-content"},[_c('article',{staticClass:"widget-bullet-list"},[_c('p',[_vm._v("Question text")]),_c('Editor',{attrs:{"api-key":"4vlyae8loqn14gjqa0ehc28htibhurk7exu430l7vn7q39l0","init":{
		menubar: false,
		statusbar: false,
		toolbar: 'bold italic underline',
      }},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}}),_c('p',{staticClass:"mt-5"},[_vm._v("List items")]),_c('ul',{staticClass:"widget-bullet-list__list",class:_vm.ordered == false ? 'unordered' : 'ordered'},_vm._l((_vm.elements),function(element){return _c('li',{key:element.id,staticClass:"widget-bullet-list__list--element",attrs:{"id":element.id}},[_c('Editor',{staticClass:"textinput px-3 pt-3",attrs:{"api-key":"4vlyae8loqn14gjqa0ehc28htibhurk7exu430l7vn7q39l0","init":{
		plugins: 'quickbars',
		quickbars_selection_toolbar: 'bold italic | blocks | underline',
		quickbars_insert_toolbar: '',
		menubar: false,
		statusbar: false,
		inline: true,
		toolbar: false,
      }},model:{value:(element.content),callback:function ($$v) {_vm.$set(element, "content", $$v)},expression:"element.content"}}),_c('span',{staticClass:"delete",on:{"click":function($event){return _vm.removeElement(element.id)}}},[_vm._v("×")])],1)}),0),_c('v-btn',{staticClass:"admin-primary-button secondary-contrast-background primary-outline mb-5",on:{"click":_vm.addElement}},[_vm._v("Add line")])],1),_c('article',[(_vm.message)?_c('form-message',{attrs:{"type":_vm.widgetMessageStatus}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1),_c('div',{staticClass:"widget-bullet-list__button"},[_c('v-btn',{staticClass:"w-100 admin-primary-button primary-contrast-background",attrs:{"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v("Done")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }