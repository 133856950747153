<template>
	<section class="widget-content">
		<article class="widget-bullet-list">
			<p>Question text</p>

			<Editor
				v-model="question"

      api-key="4vlyae8loqn14gjqa0ehc28htibhurk7exu430l7vn7q39l0"
      :init="{
		menubar: false,
		statusbar: false,
		toolbar: 'bold italic underline',
      }"
    />

			<!-- <medium-editor
					:options='options'
					class="textinput"
					v-model="question"
				label="Type question"
				auto-grow
				outlined
				rows="10"
				row-height="2"
				>
				</medium-editor> -->

			<p class="mt-5">List items</p>

			<ul
				class="widget-bullet-list__list"
				:class="ordered == false ? 'unordered' : 'ordered'"
			>
				<li
					v-for="element in elements"
					:key="element.id"
					:id="element.id"
					class="widget-bullet-list__list--element"
				>

				<Editor
				class="textinput px-3 pt-3"
				v-model="element.content"

      api-key="4vlyae8loqn14gjqa0ehc28htibhurk7exu430l7vn7q39l0"
      :init="{
		plugins: 'quickbars',
		quickbars_selection_toolbar: 'bold italic | blocks | underline',
		quickbars_insert_toolbar: '',
		menubar: false,
		statusbar: false,
		inline: true,
		toolbar: false,
      }"
    />
					<span class="delete" @click="removeElement(element.id)"
						>&times;</span
					>
				</li>
			</ul>
			<v-btn
				@click="addElement"
				class="admin-primary-button secondary-contrast-background primary-outline mb-5"
				>Add line</v-btn
			>
		</article>
		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-bullet-list__button">

			<v-btn
				class="w-100 admin-primary-button primary-contrast-background"
				:loading="isLoading"
				@click="submit"
				>Done</v-btn
			>
		</div>
	</section>
</template>

<script>
// import MediumEditor from 'vuejs-medium-editor'
// import 'medium-editor/dist/css/medium-editor.css'
// import 'vuejs-medium-editor/src/themes/default.css'
// import 'highlight.js/styles/ocean.css'

import Editor from '@tinymce/tinymce-vue'

import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import FormMessage from "@/components/FormMessage.vue";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupBulletList",
	components: { FormMessage, Editor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		content() {
			if (this.editedBlock === null) return;
			return this.editedBlock.block.content;
		},
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	data() {
		return {
			items: '',
			rules: {
				required: value => !!value || "Required."
			},
			ordered: false,
			question: "",
			elements: [],
			options: {
				placeholder: false,
				toolbar: {
				buttons: ["bold", "italic", "underline"]
				}
      		}
		};
	},

	methods: {
		initGetData() {
			if (this.editedBlock === null) {
				this.addElement();
				return;
			}
			this.ordered = Boolean(this.editedBlock?.block.ordered);
			this.question = this.editedBlock.block?.header;
			this.elements = this.editedBlock.block.items.map(el => {
				return { ...el, content: el.content, isNew: false };
			});
		},
		addElement() {
			this.elements.push({
				id: uuidv4(),
				content: "",
				isNew: true
			});
		},
		removeElement(id) {
			this.elements = this.elements.filter(el => el.id !== id);
		},
		async submit() {
			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");

			if (this.question === "" || this.elements[0].content === "") {
				this.$emit("setMessage", {
					val: "Please fill field",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}

			let question = turndownService.turndown(this.question)
			this.elements.forEach((i,v) => {
				this.elements[v].content = turndownService.turndown(i.content)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								header: question,
								head_level: 2,
								ordered: Boolean(this.ordered),
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								header: question,
								head_level: 2,
								ordered: Boolean(this.ordered),
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type,
				intro: this.currentWidget.intro,
				elements: this.elements
			};
			await this.$emit("saveBlock", payload);
		}
	},
	mounted() {
		this.initGetData();
	}
};
</script>

<style></style>
