<template>
	<v-col cols="12" class="h-calc">
		<v-row class="row-course-editor-header">
			<v-col cols="12">
				<CourseEditorHeader
					:course="course"
					:courseName="courseName"
					:courseId="courseId"
					@getCourse="getCourse"
			/></v-col>
		</v-row>
		<!-- <v-row>
			<v-col>
				<form-message v-if="message">{{ message }}</form-message>
			</v-col>
		</v-row> -->
		<v-row class="course-editor-main h-100">
			<v-col cols="2 course-editor-sidebar-main">
				<CourseEditorSidebar
					:chapters="chapters"
					:activePage="activePage"
					:introBlocks="introBlocks"
					@removePage="removePage"
					@addNewPage="addNewPage"
					@addNewChapter="addNewChapter"
					@setActivePage="setActivePage"
					@removeChapter="removeChapter"
					@changeIndexOfChapter="changeIndexOfChapter"
					@changeIndexOfPage="changeIndexOfPage"
					@openPopup="openPopup"
					@selectWidget="selectWidget"
					@editBlock="editBlock"
				></CourseEditorSidebar>
			</v-col>
			<v-col cols="10 course-editor-content-main h-100">
				<CourseEditorContent
					:chapters="chapters"
					:activePage="activePage"
					:selectedChapter="selectedChapter"
					@removeChapter="removeChapter"
					@removePage="removePage"
					@clicked="openPopup"
					@changeIndexOfChapter="changeIndexOfChapter"
					@selectWidget="selectWidget"
					@changeBlkIndex="changeBlkIndex"
					@editBlock="editBlock"
					@removeBlock="removeBlock"
					@getCourse="getCourse"
				></CourseEditorContent>
			</v-col>
			<WidgetPopup
				v-if="displayPopup == true"
				:currentWidget="currentWidget"
				:chapters="chapters"
				:editedBlock="editedBlock"
				:activePage="activePage"
				:selectedChapter="selectedChapter"
				:message="widgetMessage"
				:widgetMessageStatus="widgetMessageStatus"
				@closePopup="closePopup"
				@getCourse="getCourse"
				@saveBlock="saveBlock"
				@saveImage="saveImage"
				@setMessage="setMessage"
				@setWidgetMessageStatus="setWidgetMessageStatus"
			>
				<template #title>{{ currentWidget.name }}</template>
			</WidgetPopup>
		</v-row>
	</v-col>
</template>

<script>
import WidgetPopup from "@/views/admin/course-editor/widgets/WidgetPopup.vue";
import CourseEditorHeader from "@/views/admin/course-editor/CourseEditorHeader.vue";
import CourseEditorSidebar from "@/views/admin/course-editor/CourseEditorSidebar.vue";
import CourseEditorContent from "@/views/admin/course-editor/CourseEditorContent.vue";
import { get } from "@/util/requests/get";
import { remove } from "@/util/requests/remove";
import { post } from "@/util/requests/post";
import { put } from "@/util/requests/put";
import { endpoints } from "@/util/widgets/endpoints";
import { getPayloadForElement } from "@/util/widgets/getPayload";
import { widgets } from "@/util/widgets/widgets";
import axios from "axios";

import VueGridLayout from 'vue-grid-layout';

export default {
	name: "CourseEditor",

	components: {
		CourseEditorHeader,
		CourseEditorSidebar,
		CourseEditorContent,
		WidgetPopup,
		GridLayout: VueGridLayout.GridLayout,
		GridItem: VueGridLayout.GridItem
	},
	computed: {
		selectedChapter() {
			if (!!this.chapters === false || !!this.activePage === false)
				return {};
			return this.chapters.find(
				el => el.id === this.activePage?.chapter_id
			);
		},
		activePage() {
			if (this.chapters === undefined || this.chapters.length === 0)
				return;
			return this.chapters
				.flatMap(chapter => chapter.pages)
				.find(page => page.id === this.activePageId);
		},
		courseName() {
			return this.course.title;
		},
		courseId() {
			return Number(this.$route.params.id);
		},
		introBlocks() {
			return this.course.intro_blocks;
		}
	},
	data() {
		return {
			displayPopup: false,
			rules: {
				required: value => !!value || "Required."
			},
			currentWidget: {},
			editedBlock: null,
			activePageId: "",
			course: {},
			chapters: [],
			message: "",
			widgetMessage: "",
			widgetMessageStatus: ""
		};
	},
	methods: {
		async setMessage({ val, type }) {
			if (type === "widget") {
				this.widgetMessage = val;
				return;
			}
			this.message = val;
		},
		setWidgetMessageStatus(val) {
			this.widgetMessageStatus = val;
		},
		async getCourse() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const courseId = Number(this.$route.params.id);
				const response = await get(`/course/${courseId}`);
				if (response.status === 200) {
					const {
						data: { data }
					} = response;
					this.chapters = data.chapters;
					this.course = data;
				} else {
					this.$router.push({ name: "CourseEditor" });
				}
			} catch (error) {
				await this.setMessage({ val: error.response.data.message });
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		selectWidget(el) {
			this.currentWidget = el;
		},
		closePopup() {
			this.editedBlock = null;
			this.currentWidget = {};
			this.displayPopup = false;
			this.widgetMessageStatus = "";
			this.widgetMessage = "";
		},
		openPopup() {
			this.displayPopup = true;
		},
		async removeChapter(chapterId) {
			this.setMessage({ val: "" });
			try {
				await this.$store.dispatch("setIsLoading", true);
				await remove(`/chapter/${chapterId}`);
				await this.getCourse();
				if (this.selectedChapter === undefined) {
					this.activePageId = this.chapters[0].pages[0]?.id;
				}
			} catch (error) {
				await this.setMessage({ val: error });
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async addNewChapter() {
			this.setMessage({ val: "" });
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await post("/chapter", {
					course_id: Number(this.$route.params.id),
					title: "New Chapter",
					content: "",
					sort_order: Number(this.chapters.length),
					custom: 1,
					category_id: 1
				});
				if (response.status === 201) {
					await this.addNewPage({ chapterId: response.data.data.id });
					await this.getCourse();
				}
			} catch (error) {
				await this.setMessage({ val: error });
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async removePage(pageId) {
			this.setMessage({ val: "" });
			try {
				await this.$store.dispatch("setIsLoading", true);
				await remove(`/page/${pageId}`);
				await this.getCourse();
				if (
					this.activePageId === pageId &&
					this.selectedChapter === undefined
				) {
					this.activePageId = this.chapters[0].pages[0].id;
				}
			} catch (error) {
				await this.setMessage({ val: error });
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async addNewPage(payload) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				await post("/page", {
					chapter_id: payload.chapterId,
					title: "New page",
					content: "",
					sort_order:
						this.chapters.find(el => el.id === payload.chapterId)
							?.pages.length || 0
				});
				await this.getCourse();
			} catch (error) {
				await this.setMessage({ val: error });
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async changeIndexOfPage(payload) {
			const currentChapterIndex = this.chapters.findIndex(
				el => el.id === payload.chapterId
			);
			const currentPageIndex = this.chapters[
				currentChapterIndex
			].pages.findIndex(el => el.id === payload.pageId);
			if (
				(payload.mark === -1 && currentPageIndex === 0) ||
				(payload.mark === 1 &&
					currentPageIndex ===
						this.chapters[currentChapterIndex].pages.length - 1)
			) {
				return;
			}

			const elementsToChange = [
				this.chapters[currentChapterIndex].pages[
					currentPageIndex + Number(payload.mark)
				],
				this.chapters[currentChapterIndex].pages[currentPageIndex]
			];

			this.chapters[currentChapterIndex].pages.splice(
				currentPageIndex + Number(payload.mark),
				0,
				this.chapters[currentChapterIndex].pages.splice(
					currentPageIndex,
					1
				)[0]
			);

			await Promise.all(
				elementsToChange.map(async element => {
					await put("/page", element.id, {
						chapter_id: element.chapter_id,
						title: element.title,
						content: element.content,
						sort_order: this.chapters[
							currentChapterIndex
						].pages.findIndex(page => page.id === element.id)
					});
				})
			);
			await this.getCourse();
		},
		async changeIndexOfChapter(payload) {
			const currentChapterIndex = this.chapters.findIndex(
				el => el.id === payload.chapterId
			);
			if (
				(payload.mark === -1 && currentChapterIndex === 0) ||
				(payload.mark === 1 &&
					currentChapterIndex === this.chapters.length - 1)
			) {
				return;
			}

			const elementsToChange = [
				this.chapters[currentChapterIndex + Number(payload.mark)],
				this.chapters[currentChapterIndex]
			];

			this.chapters.splice(
				currentChapterIndex + Number(payload.mark),
				0,
				this.chapters.splice(currentChapterIndex, 1)[0]
			);
			await Promise.all(
				elementsToChange.map(async element => {
					await put("/chapter", element.id, {
						course_id: Number(this.$route.params.id),
						title: element.title,
						content: element.content,
						custom: element.custom,
						category_id: element.category_id,
						sort_order: this.chapters.findIndex(
							chapter => chapter.id === element.id
						)
					});
				})
			);
			await this.getCourse();
		},
		async changeBlkIndex(payload) {

			const currentBlockIndex = this.activePage.blocks.findIndex(
				el => el.id === payload.block.id
			);
			if (
				(payload.mark === -1 && currentBlockIndex === 0) ||
				(payload.mark === 1 &&
					currentBlockIndex === this.activePage.blocks.length - 1)
			) {
				return;
			}

			const elementsToChange = [
				this.activePage.blocks[
					currentBlockIndex + Number(payload.mark)
				],
				payload.block
			];

			this.activePage.blocks.splice(
				currentBlockIndex + Number(payload.mark),
				0,
				this.activePage.blocks.splice(currentBlockIndex, 1)[0]
			);

			await Promise.all(
				elementsToChange.map(async element => {
					await put("/page_block", element.id, {
						page_id: element.page_id,
						blk_type_id: element.blk_type_id,
						model_id: element.model_id,
						sort_order: this.activePage.blocks.findIndex(
							block => block.id === element.id
						)
					});
				})
			);
			await this.getCourse();
		},
		editBlock(block) {
			const widget = widgets.find(
				el => el.blk_type === block.blk_type_id
			);
			this.editedBlock = block;
			this.selectWidget({
				name: widget.name,
				component: widget.attribute,
				blk_type: widget.blk_type
			});
			this.openPopup();
		},
		async saveEditedBlock(event, endpoint) {
			let res = null;
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await put(
					`/${endpoint}`,
					this.editedBlock.model_id,
					event.content,
					endpoints.additionalHeader[event.blkType]
				);
				if (event.intro === true) {
					await put("/course_intro_block", this.editedBlock.id, {
						course_id: Number(this.$route.params.id),
						blk_type_id: event.blkType,
						model_id: response.data.data.id,
						sort_order: Number(response.data.data.sort_order)
					});
				} else {
					await put("/page_block", this.editedBlock.id, {
						page_id: this.activePageId,
						blk_type_id: event.blkType,
						model_id: response.data.data.id,
						sort_order: Number(response.data.data.sort_order)
					});
				}

				await this.saveItems(event, response.data.data.id);
				await this.setMessage({
					val: response.data.message,
					type: "widget"
				});
				this.widgetMessageStatus = response.data.status;
				res = response;
				this.closePopup();
			} catch (error) {
				await this.setMessage({ val: error.response.data.message });
			}
			await this.$store.dispatch("setIsLoading", false);
			return res;
		},
		async saveBlock(event) {
			try {
				await this.setMessage({ val: "" });
				await this.setMessage({ val: "", type: "widget" });
				await this.$store.dispatch("setIsLoading", true);
				const { endpoint } = widgets.find(
					el => el.blk_type === event.blkType
				);
				if (this.editedBlock === null) {
					const response = await post(
						`/${endpoint}`,
						event.content,
						endpoints.additionalHeader[event.blkType]
					);
					const {
						data: { data }
					} = response;
					if (event.intro === true) {
						await post("/course_intro_block", {
							course_id: Number(this.$route.params.id),
							blk_type_id: event.blkType,
							model_id: data.id,
							sort_order: Number(data.sort_order)
						});
					} else {
						await post("/page_block", {
							page_id: this.activePageId,
							blk_type_id: event.blkType,
							model_id: data.id,
							sort_order: Number(data.sort_order)
						});
					}
					await this.saveItems(event, data.id);
					this.closePopup();
				} else {
					await this.saveEditedBlock(event, endpoint);
				}
				await this.getCourse();
			} catch (error) {
				console.error(error);
				await this.setMessage({ val: error.response.message });
			}
			this.loadYT(true)
			await this.$store.dispatch("setIsLoading", false);
		},
		async saveConnectBlkNodes(event, parentId) {
			await Promise.all(
				event.elements.sourcesNodes.map(async (element, index) => {
					if (element.isNew === true) {
						const matchedNode = event.elements.targetNodes.find(
							targetNode => targetNode.sourceId === element.id
						);
						const sourceResponse = await post(
							"/pg_connect_blk_node",
							await getPayloadForElement(
								event.blkType,
								element,
								this.$route.params.id,
								this.activePage,
								index,
								parentId
							)
						);
						await post(
							"/pg_connect_blk_node",
							await getPayloadForElement(
								event.blkType,
								matchedNode,
								this.$route.params.id,
								this.activePage,
								index,
								parentId,
								sourceResponse.data.data.id
							)
						);
					} else {
						const matchedNode = event.elements.targetNodes.find(
							targetNode =>
								targetNode.pg_connect_blk_node_id === element.id
						);
						await put(
							"/pg_connect_blk_node",
							element.id,
							await getPayloadForElement(
								event.blkType,
								element,
								this.$route.params.id,
								this.activePage,
								index,
								parentId
							)
						);
						await put(
							"/pg_connect_blk_node",
							matchedNode.id,
							await getPayloadForElement(
								event.blkType,
								matchedNode,
								this.$route.params.id,
								this.activePage,
								index,
								parentId,
								matchedNode.pg_connect_blk_node_id
							)
						);
					}
				})
			);
		},
		async saveItems(event, parentId) {
			if (
				event.blkType !== 6 &&
				event.blkType !== 4 &&
				event.blkType !== 10
			) {
				if (event.blkType === 2) {
					await this.saveConnectBlkNodes(event, parentId);
					return;
				}
				Promise.all(
					event.elements.map(async (element, index) => {
						const payload = await getPayloadForElement(
							event.blkType,
							element,
							this.$route.params.id,
							this.activePage,
							index,
							parentId
						);
						if (element.isNew === true) {
							const response = await post(
								`/${endpoints.elements[event.blkType]}`,
								payload,
								endpoints.additionalHeader[event.blkType]
							);
						} else {
							const response = await put(
								`/${endpoints.elements[event.blkType]}`,
								element.id,
								payload,
								endpoints.additionalHeader[event.blkType]
							);
						}
					})
				);
			}
		},
		async removeBlock(event) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				await remove(`/page_block/${event.blkId}`);
				await this.getCourse();
			} catch (error) {
				await this.setMessage({ val: error.response.data.message });
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		setActivePage(id) {
			this.activePageId = id;
		},
		async saveImage(event) {
			try {
				const { endpoint } = widgets.find(
					el => el.blk_type === event.blkType
				);
				const response = await post(
					`/${endpoint}`,
					event.content,
					endpoints.additionalHeader[event.blkType]
				);

				// const {
				// 	data: { data }
				// } = response;

				// return response

			} catch (error) {
				console.error(error);
			}
		},
		loadYT(status = false) {

			setTimeout(function() {
					if(status) {
						$('.yt-player').remove()
						$('figure').find('a').each(function() {
							let link = $(this).attr('href').toString()
							if(link.indexOf("https://www.youtube.com") > -1) {

								$(this).hide()
								axios.get('https://iframe.ly/api/iframely?url='+link+'&api_key=9935a91a409d0d89f1cfd7').then((res) => {
									$(this).parent().append('<div class="yt-player">'+res.data.html+'</div>')
								})
							}
						})
					} else {
						$('figure').find('a').each(function() {
							let link = $(this).attr('href').toString()
							if(link.indexOf("https://www.youtube.com") > -1) {

								$(this).hide()
								axios.get('https://iframe.ly/api/iframely?url='+link+'&api_key=9935a91a409d0d89f1cfd7').then((res) => {
									$(this).parent().append('<div class="yt-player">'+res.data.html+'</div>')
								})
							}
						})
					}


					$('article').find('table').each(function() {
						$(this).addClass('table table-bordered')
						$(this).find('thead').addClass('thead-dark')
					})

				}, 100)

		}
	},
	async created() {
		await this.getCourse();
		this.activePageId = this.chapters[0].pages[0]?.id;

		this.loadYT()
	},
};
</script>

<style lang="scss">
.h-calc {
	height: calc(100% - 185px) !important;
}

.row-course-editor-header {
	border-bottom: 1px solid #d1d1d1;
}
</style>
