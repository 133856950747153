<template>
	<section class="widget-content">
		<article class="widget-text-block">


		<Editor
			class="textinput px-3 pt-3 pb-0"
			v-for="(paragraph, index) in paragraphs"
			v-model="paragraph.content"
			:key="paragraph.id"
			:api-key="tinyAPI"
			:init="options"
		/>



			<!-- <v-btn
				@click="addParagraph"
				class="admin-primary-button secondary-contrast-background primary-outline mr-5 mb-5 mt-5"
				>Add line</v-btn
			> -->


		</article>
		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
	>Done</v-btn
>
</div>

	</section>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import axios from "axios";
import FormMessage from "@/components/FormMessage.vue";
import { endpoints } from "@/util/widgets/endpoints";
import { mapGetters } from "vuex";
import { post } from "@/util/requests/post";
import { get } from "@/util/requests/get";
import { v4 as uuidv4 } from "uuid";
import { widgets } from "@/util/widgets/widgets";

import TurndownService from 'turndown'
import { gfm } from 'turndown-plugin-gfm'
import marked from 'marked'
import markdownIt from 'markdown-it';
import { Promise } from 'q';

// const md = require('markdown-it')().use(require('markdown-it-html5-embed'), {
// 	html5embed: {
// 		useImageSyntax: true,
// 		useLinkSyntax: true
// 	}
// })

const turndownService = new TurndownService()
turndownService.use(gfm)

const md = markdownIt().use(require("markdown-it-multimd-table"))

export default {
	name: "WidgetPopupTextBlock",
	components: { FormMessage, Editor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["userId", "isLoading"]),
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},

	data() {
		return {
			tinyAPI: tinyAPI,
			rules: {
				required: value => !!value || "Required."
			},
			paragraphs: [],
			imageFile: false,
			options: {
				height: 500,
				menubar: false,
				plugins: [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code fullscreen',
					'insertdatetime media mediaembed table paste code help wordcount'
				],
				toolbar: 'undo redo | formatselect | ' +
				'bold italic | alignleft aligncenter ' +
				'alignright alignjustify | bullist numlist |' +
				'image media mediaembed table code',
				content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 15px }',
				forced_root_block: '',
				image_title: true,
				media_live_embeds: true,
				automatic_uploads: true,
				images_file_types: 'jpg, svg, png, mp4, mov',
				file_picker_types: 'file image media',
				file_picker_callback:  this.uploadImage,
				cleanup_on_startup: true,
				media_url_resolver: function (data, resolve) {

					axios.get('https://iframe.ly/api/iframely?url='+data.url+'&api_key=9935a91a409d0d89f1cfd7').then((res) => {
						let video = '<a href="'+res.data.url+'"><img class="yt-video" src="'+res.data.links.thumbnail[1].href+'" alt="YouTube"></a>'
						resolve({ html: video });
					})

					// var video = '<'+data.url+'>';
				}
			}
		};
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	methods: {
		async uploadImage(cb, value, meta) {

			let type = 'pg_image_blk',
				title = 'Image'

			if(meta.filetype == 'media') {
				type = 'pg_video_blk'
				title = 'Video'
			}

			const payload = {
				content:
					this.editedBlock !== null
						? {
							course_id: Number(this.$route.params.id),
							chapter_id: this.editedBlock.block.chapter_id,
							page_id: this.editedBlock.block.page_id,
							title: this.editedBlock.block.title,
							head_level: this.editedBlock.block.head_level,
							caption: this.url ? this.url : "empty",
							css_class: this.editedBlock.block.css_class,
							sort_order: this.editedBlock.block.sort_order,
							header: ''
						}
						: {
							course_id: Number(this.$route.params.id),
							chapter_id: this.activePage.chapter_id,
							page_id: this.activePage.id,
							header: '',
							title: title,
							head_level: 2,
							caption: this.url ? this.url : "empty",
							css_class: null,
							sort_order: Number(
								this.activePage.blocks.length
							)
					},
				blkType: this.currentWidget.blk_type
			};

			var input = document.createElement('input');
			input.setAttribute('type', 'file');
			input.setAttribute('accept', 'image/*, video/mp4');
			input.onchange = async function () {
				var file = this.files[0];
				var reader = new FileReader();

				var fd = new FormData();
				var files = file;

				for (const key in payload.content) {
					if (typeof payload.content[key] === "string") {
						fd.append(key, payload.content[key]);
					} else {
						fd.append(key, JSON.stringify(payload.content[key]));
					}
				}

				fd.append('filetype',meta.filetype);
				fd.append("file",files);

				var filename = "";

				const { endpoint } = widgets.find(
					el => el.blk_type === 9
				);
				const response = await post(
					'/' + type,
					fd,
					endpoints.additionalHeader[9]
				);

				const getImgObj = await get('/file/' + response.data.data.file_id);
				filename = getImgObj.data.data.public_url

					reader.onload = function () {
						cb(filename);
					};
					reader.readAsDataURL(file);

			}

			input.click();
		},
		doMark(e) {
			return marked(e)
		},
		addParagraph(id = uuidv4(), content = "", isNew = true) {
			this.paragraphs.push({
				id: id,
				content: content,
				isNew: isNew
			});
		},
		initGetData() {
			if (this.editedBlock === null) {
				this.addParagraph();
			} else {
				this.editedBlock.block.paragraphs.forEach(el =>
					this.addParagraph(el.id, md.render(el.content), false)
				);
			}
		},
		async submit() {

			this.paragraphs.forEach((i,v) => {
				this.paragraphs[v].content = turndownService.turndown(i.content)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								content: this.editedBlock.block.content,
								head_level: this.editedBlock.block.head_level,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								title: "",
								content: "",
								head_level: 2,
								css_class: null,
								sort_order:
									Number(this.activePage.blocks.length) + 1
						  },
				blkType: this.currentWidget.blk_type,
				intro: this.currentWidget.intro,
				elements: this.paragraphs
			};
			this.$emit("saveBlock", payload);

			// console.log(payload.elements[0].content)
			// console.log(turndownService.turndown('<a href="http://google.com.au/"><img src="http://www.google.com.au/images/nav_logo7.png" alt="Foo"></a>'))
			// console.log(md.render('[![Foo](http://www.google.com.au/images/nav_logo7.png)](http://google.com.au/)'))
		}
	},
	mounted() {
		this.initGetData();
	},
};
</script>

<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}

// img[alt='YouTube'] {
// 	width: 100%;
// }

.tox-browse-url {
	// display: none !important;
}
</style>
